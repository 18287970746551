import { useState } from "react";

import "./Contact.scss";
import SectionHeader from "../common/SectionHeader/SectionHeader";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const submit = () => {};

  return (
    <section id="contact">
      <div className="container">
        <SectionHeader
          shortTitle="CONTACT"
          title="CONTACT ME"
          content="You can contact me through email, just fill in the blanks."
        ></SectionHeader>
        <form action="">
          <div className="form-items">
            <input
              type="text"
              placeholder="Full Name"
              required
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              placeholder="Email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Message"
              required
              onChange={(e) => setMessage(e.target.value)}
            />
            <button onClick={submit}>Send</button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact;
