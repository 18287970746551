import "./header.scss";
import draxexLogo from "../../assets/draxex-logo.png";
import SocialMedia from "../common/SocialMedia/SocialMedia";

const Header = () => {
  return (
    <header>
      <div className="header-text">
        <SocialMedia />
        <h1>Hey, this is Draxex</h1>
        <p>
          Professional full-stack developer with long-time experience in this
          field
        </p>
        <a href="#about" className="bordered-button">
          My Portfolio
        </a>
      </div>
      <div className="header-logo">
        <img src={draxexLogo} alt="draxex logo"></img>
      </div>
    </header>
  );
};

export default Header;
