import "./SocialMedia.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SocialMediaItem = ({ item }) => {
  return (
    <a
      href={item.href}
      target="_blank"
      rel="noreferrer"
      className="social-media-item"
    >
      <FontAwesomeIcon icon={item.icon} className="social-media-item-icon" />
    </a>
  );
};

export default SocialMediaItem;
