import "./Footer.scss";
import SocialMedia from "../SocialMedia/SocialMedia";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <h1>draxex</h1>
        <p>
          To get more information about me and my projects, please check the
          links down below.
        </p>
        <SocialMedia />
      </div>
    </footer>
  );
};

export default Footer;
