import "./Projects.scss";
import SectionHeader from "../common/SectionHeader/SectionHeader";
import ProjectItem from "./ProjectItem";
import { projectItems } from "./utils";

const Projects = () => {
  return (
    <section id="projects">
      <div className="container">
        <SectionHeader
          shortTitle="PROJECTS"
          title="MY PROJECTS"
          content="Some of my personal projects as an individual developer. To check the whole projects that I worked on, please visit my LinkedIn profile."
        ></SectionHeader>
        <div className="projects-grid">
          {projectItems.map((item) => (
            <ProjectItem item={item} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
