export const experienceItems = [
  {
    title: "Flutter",
    percent: 100,
  },
  {
    title: "Java",
    percent: 90,
  },
  {
    title: "JavaScript",
    percent: 80,
  },
  {
    title: "C#",
    percent: 70,
  },
  {
    title: "NodeJS",
    percent: 70,
  },
  {
    title: "React, Next.js",
    percent: 60,
  },
  {
    title: "Asp.NET Core MVC",
    percent: 70,
  },
  {
    title: "Native Android Development",
    percent: 70,
  },
  {
    title: "Git",
    percent: 80,
  },
  {
    title: "Unity",
    percent: 60,
  },
  {
    title: "Database",
    percent: 80,
  },
  {
    title: "Services (Firebase, AWS)",
    percent: 60,
  },
  {
    title: "UI/UX Design",
    percent: 85,
  },
  {
    title: "HTML & CSS",
    percent: 70,
  },
  {
    title: "REST API",
    percent: 80,
  },
  {
    title: "Testing",
    percent: 50,
  },
];
