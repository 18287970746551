import "./about.scss";
import SectionHeader from "../common/SectionHeader/SectionHeader";
import draxexPhoto from "../../assets/draxex-photo.png";

const About = () => {
  return (
    <section id="about">
      <div className="container">
        <SectionHeader
          shortTitle="ABOUT"
          title="ABOUT ME"
          content="A computer engineer who is working as a full-stack developer"
        ></SectionHeader>
        <div className="about-content">
          <div className="image-bg-container">
            <img src={draxexPhoto} alt="draxex" />
          </div>
          <div className="about-text-content">
            <h5>Hi There</h5>
            <p>
              I am Burak, a computer engineer. I'm mostly developing mobile
              applications for personal and commercial purposes. I have 3+ years
              of experience in Mobile App Development. I am developing my own
              apps on{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/dev?id=5207403302472357174"
              >
                Google Play
              </a>
              . Now I use Flutter for Android and IOS app development.
              <br />
              <br />
              Also, I have quite a lot of experience working on the backend
              side. When I start my development journey, I was using Asp.NET
              Core MVC to develop on the backend side but now I'm developing
              with Node.js Express. When I was studying at university, the first
              language that I learned was Java. Therefore I have great Java
              skills. I started my mobile app development career with native
              Android using Java programming language. Then I started to develop
              with Flutter because It was really easy to publish an application
              for both iOS and Android.
              <br />
              <br />I really like to create and design something. I usually
              design my own applications by myself. Because I believe in my
              ability about designing. When I start designing my applications, I
              was using Adobe XD. Right now I'm using Figma. Also, I'm really
              comfortable with using Adobe Photoshop, Illustrator, and After
              Effects.
              <br />
              <br />
              The first application that I published was a game. The name of the
              game is{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.Draxex.Dream"
              >
                Dream
              </a>
              . It's a 2D Top Down Shooter game. I made this game with Unity
              using C# programming language. That was my first experience
              creating a mobile app and publishing it to the Play Store. I
              designed all characters and items with Photoshop, Illustrator,
              etc.
              <br />
              <br />
              Software Development isn't a job just for me or a way of earning
              money. It's a passion for me because I really like to create
              products and present them to customers. I really enjoy getting
              feedback about my products, and if they are useful for some
              people, that's a great feeling for me. Also, I really like to
              solve problems and contribute to the world in a positive way.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
