export const MenuItems = [
  {
    href: "#",
    title: "Home",
  },
  {
    href: "#about",
    title: "About",
  },
  {
    href: "#experience",
    title: "Experience",
  },
  {
    href: "#projects",
    title: "Projects",
  },
  {
    href: "#contact",
    title: "Contact",
  },
];
