import dreamImage from "../../assets/dream.webp";
import dopamineDetoxImage from "../../assets/dopamine-detox.webp";
import feelingGoodImage from "../../assets/feeling-good.webp";
import myVocImage from "../../assets/myvoc-icon.png";
import toDoImage from "../../assets/todo-icon.png";
import wecacImage from "../../assets/wecac-icon.webp";

export const projectItems = [
  {
    image: dreamImage,
    title: "Dream",
    description:
      "This top down shooter game will make you feel excited on every stage while you encounter monsters. You have to be quick to defeat your enemies. They can appear instantly.",
    href: "https://play.google.com/store/apps/details?id=com.Draxex.Dream",
  },
  {
    image: dopamineDetoxImage,
    title: "Dopamine Detox",
    description:
      "Dopamine Detox App is the best way to get rid of your addictions and bad habits easily. Download the app right now and start a big journey to defeat your bad habits with your short-term plans.",
    href: "https://play.google.com/store/apps/details?id=com.draxex.dopamine_detox",
  },
  {
    image: feelingGoodImage,
    title: "Feeling Good",
    description:
      "Feeling good is what you owe yourself. Download the app right now and wipe away negative thoughts with forms prepared in the light of Cognitive Behavioral Therapy (CBT).",
    href: "https://play.google.com/store/apps/details?id=com.draxex.cbt",
  },
  {
    image: myVocImage,
    title: "MyVoc - Learn A New Word",
    description:
      "By pronouncing words and phrases with your own voice will expand your vocabulary effectively. You will memorize new words easily and use them fluently while speaking with other people. This is the best way to learn a language and learn a new word everyday.",
    href: "https://play.google.com/store/apps/details?id=com.draxex.myvoc",
  },
  {
    image: toDoImage,
    title: "ToDo - Yapılacaklar Listesi",
    description:
      "ToDo App allows you to have a proactive day. You can create your own to-do list and manage your time effectively.",
    href: "https://play.google.com/store/apps/details?id=com.draxex.todo",
  },
  {
    image: wecacImage,
    title: "WECAC",
    description:
      "This is a Freelancer Project about education that I developed.",
    href: "https://play.google.com/store/apps/details?id=com.wecactech.wecac",
  },
];
