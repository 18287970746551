import "./Projects.scss";

const ProjectItem = ({ item }) => {
  return (
    <a
      href={item.href}
      target="_blank"
      rel="noreferrer"
      className="project-item"
    >
      <div className="project-item-circular-image">
        <img src={item.image} alt={item.title} />
      </div>
      <h5>{item.title}</h5>
      <p className="small-text">{item.description}</p>
    </a>
  );
};

export default ProjectItem;
