import "./SocialMedia.scss";
import { socialMediaItems } from "./utils";
import SocialMediaItem from "./SocialMediaItem";

const SocialMedia = () => {
  return (
    <div className="social-media-items">
      {socialMediaItems.map((item) => (
        <SocialMediaItem item={item} />
      ))}
    </div>
  );
};

export default SocialMedia;
