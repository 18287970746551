import "./nav.scss";
import { MenuItems } from "./utils";

const Nav = () => {
  return (
    <nav>
      <div className="nav-items">
        <a href="#" className="nav-logo">
          draxex
        </a>
        <ul className="nav-links">
          {MenuItems.map((item) => (
            <li className="nav-item">
              <a href={item.href}>{item.title}</a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
