import "./Experience.scss";

const ExperienceItem = ({ title, percent }) => {
  return (
    <div className="experience-item-object">
      <p>{title}</p>
      <div className="line-bar-container">
        <div className="line-bar" style={{ width: `${percent}%` }}></div>
      </div>
    </div>
  );
};

export default ExperienceItem;
