import {
  faGooglePlay,
  faLinkedinIn,
  faGithub,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

export const socialMediaItems = [
  {
    href: "https://play.google.com/store/apps/dev?id=5207403302472357174",
    icon: faGooglePlay,
  },
  {
    href: "https://www.linkedin.com/in/burak-y%C4%B1lmaz-b5492a201/",
    icon: faLinkedinIn,
  },
  {
    href: "https://github.com/draxexx/",
    icon: faGithub,
  },
  {
    href: "https://www.instagram.com/draxex1/",
    icon: faInstagram,
  },
];
