import "./SectionHeader.scss";

const SectionHeader = ({ shortTitle, title, content }) => {
  return (
    <div className="section-header">
      <h6>{shortTitle}</h6>
      <h4>{title}</h4>
      <p>{content}</p>
      <div className="line"></div>
    </div>
  );
};

export default SectionHeader;
