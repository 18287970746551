import "./Experience.scss";
import SectionHeader from "../common/SectionHeader/SectionHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import ExperienceItem from "./ExperienceItem";
import { experienceItems } from "./utils";

const Experience = () => {
  return (
    <section id="experience">
      <div className="container">
        <SectionHeader
          shortTitle="EXPERIENCE"
          title="MY EXPERIENCE"
          content="Here are my skills in Software Development."
        ></SectionHeader>
        <div className="experiences">
          {experienceItems.map((item) => (
            <div className="experience-item">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="experience-icon"
              />
              <ExperienceItem title={item.title} percent={item.percent} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Experience;
